import React, { useState, useRef, useEffect } from 'react';
import InputValidation from '../../components/utils/InputValidation';
import logo from '../../assets/img/logo_yiga_blanco.png'
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../redux/actions/userActions'
import { auth, db, functions, firebase } from '../../db/firebase'
import { Container, Card, CardContent, Typography, Paper, Hidden, Grid, Alert, Box } from '@mui/material';
import img from '../../assets/img/eli.png';
import y5 from '../../assets/img/y5.png'

import proximamente from '../../assets/img/proximamente.png';
import disaponible from '../../assets/img/disaponible.png'
import img_back from '../../assets/img/fondo.png';
import PaymentIcon from '@mui/icons-material/Payment';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import IconButton from '@mui/material/IconButton';
import LockIcon from '@mui/icons-material/Lock';

import LoadingButton from '@mui/lab/LoadingButton';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { useTheme, ThemeProvider, createTheme } from '@mui/material/styles';
export const LoginPage = () => {
  const [email, setEmail] = useState({ campo: '', valido: true })
  const [password, setPassword] = useState({ campo: '', valido: null })
  const userLogin = useSelector(state => state.userLogin)
  const { error: errorCreate, loading } = userLogin
  //
  const userSignup = useSelector(state => state.userSignup)
  const { signup } = userSignup

  //
  const dispatch = useDispatch()
  useEffect(() => {
  }, [loading])
  const submitHandler = (e) => {
    e.preventDefault()
    try {
      if (email.valido === true && password.valido) {
        dispatch(login({
          'dni': email.campo,
          'password': password.campo,
        }))
      }

    } catch (error) {
    }
  }

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const obtenerDatosAbitmedia = async () => {
    try {
      const snapshot = await db.collection("abitmedia_debitoauto").get();
      const documentos = snapshot.docs.map((doc) => doc.data());

      for (const dataAbitmedia of documentos) {
        await procesarDocumento(dataAbitmedia);
        await delay(5000); // Espera 5 segundos antes de procesar el siguiente
      }
    } catch (error) {
      console.error("Error obteniendo documentos:", error);
    }
  };

  const procesarDocumento = async (dataAbitmedia) => {
    try {
      const token = await obtenerToken(dataAbitmedia.cedula);
      if (token) {
        const cliente = await obtenerDetallesCliente(dataAbitmedia);
        if (cliente) {
          const diaPago = await obtenerDiaPago(cliente);
          console.log(diaPago);
          if (diaPago) {
            await actualizarDiaPago(dataAbitmedia, diaPago);
          }
        }
      }
    } catch (error) {
      console.error(`Error procesando ${dataAbitmedia.cedula}:`, error);
    }
  };

  const obtenerToken = async (cedula) => {
    const url = `https://api.abitmedia.cloud/pagomedios/v2/cards?integration=true&document=${cedula}`;
    const headers = { Authorization: "Bearer mjwguxipbjg1iitxkjxb6tz6s3tl8f-rrgi371ad1sjkhgnrs0fcvvn8tkzworjbkcbsm" };

    try {
      const response = await fetch(url, { method: "GET", headers });
      const result = await response.json();
      return result?.data[0]?.cards[0]?.token || null;
    } catch (error) {
      console.error("Error obteniendo token:", error);
      return null;
    }
  };

  const obtenerDetallesCliente = async (dataAbitmedia) => {
    const url = "https://dataguardian.intercommerce.com.ec/funcion/mikroGetClientsDetails/";
    const headers = { "Content-Type": "application/json" };
    const body = JSON.stringify({ token: "VzhoNEE2ZWlEdFFDUTJMaUJOS21Edz09", cedula: dataAbitmedia.cedula });

    try {
      const response = await fetch(url, { method: "POST", headers, body });
      const result = await response.json();

      const transformarValor = (valor) => ({ 1: 0, 2: 1 }[valor] ?? valor);
      return result?.datos[transformarValor(dataAbitmedia.linea)] || null;
    } catch (error) {
      console.error("Error obteniendo detalles del cliente:", error);
      return null;
    }
  };

  const obtenerDiaPago = async (cliente) => {
    const url = `https://serverbd.intercommerce.com.ec/apiMikrowispFacturas/?plantilla_idcliente=${cliente.id}`;

    try {
      const response = await fetch(url, { method: "GET" });
      const result = await response.json();
      return result[0]?.diapago || null;
    } catch (error) {
      console.error("Error obteniendo día de pago:", error);
      return null;
    }
  };

  const actualizarDiaPago = async (dataAbitmedia, diaPago) => {
    try {
      console.log(`Día de pago actualizado para ${dataAbitmedia.cedula}: ${diaPago}`);
      await db.collection("abitmedia_debitoauto_2025")
        .doc(`${dataAbitmedia.nombre} ${dataAbitmedia.cedula}`)
        .set({

          ...dataAbitmedia,
          diapago: diaPago
        });


    } catch (error) {
      console.error("Error actualizando día de pago:", error);
    }
  };
  const updateUser = (e) => {
    console.log('a')
    const data = {
      cargo: 0,
      email: 'celinxiomara@gmail.com',
      departamento: 0,
      rol: 0,
    }
    const createUser = functions.httpsCallable('updateUser')
    createUser(data)
      .then(res => {
        console.log('Se actualizo usuario: ', res)
      })
      .catch(error => console.log(error))


  }
  const test1 = () => {
    //
  }
  const load1 = () => {
    const data_data = []
    var i = 0
    setInterval(() => {
      const item = data_data[i++]
      if (item.correo) {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({
          "correo": item.correo
        });
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };
        fetch("https://us-central1-sistema-gestion-intercommerce.cloudfunctions.net/api/email", requestOptions)
          .then(response => response.text())
          .then(result => {
            console.log(item.nombre, ' ', item.correo, ' CORREO ENVIADO')

          })
          .catch(error => console.log('error', error));

        // var myHeaders = new Headers()
        // myHeaders.append("Content-Type", "application/json")
        // var raw = JSON.stringify({
        //   "cedula":  `${item.cedula}`,
        //   "nombres": item.nombre,
        //   "direccion": item.direccion_principal,
        //   "provincia": "null",
        //   "canton": "null",
        //   "sector": "null",
        //   "zona": "null",
        //   "parroquia": "null",
        //   "coordenadas": "null",
        //   "telefono": item.telefono ? `${item.telefono}` : 'null',
        //   "celular": item.movil ? `${item.movil}` : 'null',
        //   "whatapp": "null",
        //   "numReferencia": "null",
        //   "estado": item.estado,
        //   "cuentabancaria": "null",
        //   "banco": "null",
        //   "tipobanco": "null",
        //   "fechanacimiento": "null",
        //   "accededebitobancario": "null",
        //   "codigopago": item.codigo_pago,
        //   "gradodiscapacidad": "null",
        //   "gradodiscapacitado": "null",
        //   "numContrato": item.NUM_CONTRATO,
        //   "obtieneCamara": "null",
        //   "cantidadCamara": "null",
        //   "correo":  item.correo ? item.correo : 'null',
        //   "vendedor": item.AGENTE_VENDEDOR,
        //   "idmikrowisp": item.id,
        // })
        // var requestOptions = {
        //   method: 'POST',
        //   headers: myHeaders,
        //   body: raw
        // }
        // fetch("http://192.168.90.24:3000/crearCliente", requestOptions)
        //   .then(response => response.text())
        //   .then(result => {
        //     console.log(item.nombre, ` `, item.NUM_CONTRATO, ' ',item.estado,' INGRESADO A SOEI')
        //     console.log(result)
        //   })
        //   .catch(error => console.log('error', error))
      }
    }, 500)

    // var myHeaders = new Headers();
    // myHeaders.append("Content-Type", "application/json");

    // var raw = JSON.stringify({
    //   "cedula": "953416270",
    //   "nombres": "SEBASTIAN MITE TORRES",
    //   "direccion": "111",
    //   "provincia": "11",
    //   "canton": "111",
    //   "sector": "111",
    //   "zona": "111",
    //   "parroquia": "111",
    //   "coordenadas": "111",
    //   "telefono": "111",
    //   "celular": "111",
    //   "whatapp": "111",
    //   "numReferencia": "111",
    //   "estado": "111",
    //   "cuentabancaria": "1111",
    //   "banco": "111",
    //   "tipobanco": "111",
    //   "fechanacimiento": "111",
    //   "accededebitobancario": "111",
    //   "codigopago": "10000s",
    //   "gradodiscapacidad": "111",
    //   "gradodiscapacitado": "111",
    //   "numContrato": "111",
    //   "obtieneCamara": "111",
    //   "cantidadCamara": "1111",
    //   "email": "lianmejia@gmail.com",
    //   "vendedor": "Sebastian Mite"
    // });

    // var requestOptions = {
    //   method: 'POST',
    //   headers: myHeaders,
    //   body: raw,
    //   redirect: 'follow'
    // };

    // fetch("http://192.168.90.24:3000/crearCliente", requestOptions)
    //   .then(response => response.text())
    //   .then(result => console.log(result))
    //   .catch(error => console.log('error', error));

  }
  const fetchFechaContable = async () => {
    try {
      const docRef = db.collection('fechaContable').doc('fechaActual');
      const docSnap = await docRef.get();
      if (docSnap.exists) {
        console.log(docSnap.data().fechaContable);
      } else {
        const fechaActual = new Date();
        const año = fechaActual.getFullYear();
        const mes = String(fechaActual.getMonth() + 1).padStart(2, '0');
        const dia = String(fechaActual.getDate()).padStart(2, '0');

        const fechaContableCalculado = parseInt(`${año}${mes}${dia}`, 10);

        console.log(fechaContableCalculado);
      }
    } catch (error) {
      console.error('Error obteniendo fecha contable:', error);
      // Fallback local
      const fechaActual = new Date();
      const año = fechaActual.getFullYear();
      const mes = String(fechaActual.getMonth() + 1).padStart(2, '0');
      const dia = String(fechaActual.getDate()).padStart(2, '0');
      console.log(parseInt(`${año}${mes}${dia}`, 10));
    }
  };
  let theme = createTheme({
    palette: {

      background: {
        default: '#1d0226',
        paper: '#2D033B',
      },
      primary: {
        main: '#7635DC'
      },
      secondary: {
        main: '#6d1b7b',
      },

      //},
      // typography: {
      //     button: {
      //     textTransform: "none"
      //}
    },
    components: {
      // Name of the component

      MuiButton: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            borderRadius: '1rem',
            textTransform: 'none',
            fontSize: '1rem',
            boxShadow: '0 8px 20px -12px rgba(0,0,0,0.5)',
          },
        },
      },

      MuiCard: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            boxShadow: '0 8px 20px -12px rgba(0,0,0,0.3)',
            borderRadius: '1rem',
          },
        },
      },
    },
  })
  const funcionAbitmedia = async () => {
    try {
      console.log('2')
      const qs = await db.collection("abitmedia_tokens")
        .orderBy("created", "desc")
        .limit(10) 
        .get();
      console.log('2')
      for (const doc of qs.docs) {
        console.log('3')
       
        const data = doc.data();
        console.log('estado:', data.estado);
        //Estado 0 es cuando el token no esta utilizado
        if (data.estado === 0) {
          const fs = new Date().toLocaleString("en-US", {
            timeZone: "America/Guayaquil",
          });

          console.log(" fecha generada1: ", data.fecha_generada);
          var fecha_actual = new Date(fs);

          var fecha_generada = new Date(data.fecha_generada);
          console.log(" fecha generada 3,  de data: ", fecha_generada);
          var fecha_limite = fecha_generada;
          fecha_limite.setMinutes(fecha_generada.getMinutes() + 10);
          fecha_limite.setSeconds(0);
          console.log(data.nombre);
          console.log(" fecha servidor: ", fecha_actual);
          console.log(" fecha limite: ", fecha_limite);
          console.log(" ");
          //if (fecha_actual < fecha_limite) {
            console.log(" ");
            console.log(data.nombre);
            console.log("fecha_limite: ", fecha_limite);
            console.log("fecha_actual: ", fecha_actual);
            console.log("token: ", data.token);
            console.log(" ");
            const hours = fecha_actual.getHours();
            const minutes = fecha_actual.getMinutes();
            const seconds = fecha_actual.getSeconds();
            const day = fecha_actual.getDay();
            /// Funcione nuevas para fechas ROBIN 
            async function formatDateToAAAAMMDD(fecha) {
              const year = fecha.getFullYear();
              const month = String(fecha.getMonth() + 1).padStart(2, "0");
              const docRef = db.collection('fechasContables').doc('fechaActual');
              const docSnap = await docRef.get();
              if (docSnap.exists) {
                const day = docSnap.data().fechaContable;
                return `${year}${month}${day}`
              } else {
                const day = String(fecha.getDate()).padStart(2, "0");
                return `${year}${month}${day}`;
              }
            }
            function getFechaContable(fecha_transaccion) {
              var fecha_itrans = new Date(fecha_transaccion);

              var day = fecha_transaccion.getDay();
              var hours = fecha_transaccion.getHours();

              if (day === 6 || day === 0) {
                fecha_itrans.setHours(8);
                fecha_itrans.setMinutes(0);
                fecha_itrans.setSeconds(0);

                if (day === 6) {
                  fecha_itrans.setDate(fecha_itrans.getDate() + 2);
                }
                if (day === 0) {
                  fecha_itrans.setDate(fecha_itrans.getDate() + 1);
                }
                return fecha_itrans;
              }

              if (day === 5 && hours > 15) {
                fecha_itrans.setHours(8);
                fecha_itrans.setMinutes(0);
                fecha_itrans.setSeconds(0);
                fecha_itrans.setDate(fecha_itrans.getDate() + 3);
                return fecha_itrans;
              }


              if (hours < 7) {
                fecha_itrans.setHours(8);
              } else if (hours > 15) {
                console.log("FECHA MAS UN DIA, NO ESTA EN EL RANGO");
                fecha_itrans.setHours(8);
                fecha_itrans.setMinutes(0);
                fecha_itrans.setSeconds(0);
                fecha_itrans.setDate(fecha_itrans.getDate() + 1);
              }

              return fecha_itrans;
            }
            const veruficatamano = (string1) => {
              const numero = string1.length;
              return numero > 9 ? numero + string1 : "0" + numero + string1;
            };
            function formatTimeToHHMMSS(fecha_hoy) {
              const hours = String(fecha_hoy.getHours()).padStart(2, "0");
              const minutes = String(fecha_hoy.getMinutes()).padStart(2, "0");
              const seconds = String(fecha_hoy.getSeconds()).padStart(2, "0");
              return `${hours}${minutes}${seconds}`;
            }

            ///////////



            let fetch = require("node-fetch");

            let myHeaders = new fetch.Headers();

            myHeaders.append(
              "Authorization",
              "Bearer mjwguxipbjg1iitxkjxb6tz6s3tl8f-rrgi371ad1sjkhgnrs0fcvvn8tkzworjbkcbsm"
            );

            var requestOptions = {
              headers: myHeaders,
              method: "GET",
            };
            // return fetch(
            //   `https://api.abitmedia.cloud/pagomedios/v2/payment-requests/?id=${data.token}`,
            //   requestOptions
            // )
            const response = await fetch(
              `https://api.abitmedia.cloud/pagomedios/v2/payment-requests/?id=${data.token}`,
              requestOptions
            );
            if (!response.ok) {
              const errorText = await response.text();
              throw new Error(`Error HTTP ${response.status}: ${errorText}`);
            }
            const result = await response.text();
            const re = JSON.parse(result);

            console.log("estado, token : ", re.data[0].status, data.token);
            //console.log('abitmedia: ', re)

            //if (re.data.estado === 1) {
            if (re.data[0].status === 1) {
              ////
              // ROBIN, FECHA DESDE ABITMEDIA
              console.log(re.data[0].payment_date)
              const payment_date = new Date(re.data[0].payment_date);
              console.log("payment_date", payment_date)
              const fecha_contable_abi = getFechaContable(payment_date)

              const fecha_contable_abitmedia = formatDateToAAAAMMDD(fecha_contable_abi);
              const fecha_abitmedia = formatDateToAAAAMMDD(payment_date)
              const hora_abitmedia = formatTimeToHHMMSS(payment_date)

              console.log("FECHA PAGO ABITMEDIA", fecha_abitmedia, hora_abitmedia)
              console.log("FECHA CONTABLE ABITMEDIA", fecha_contable_abitmedia)
              //////////////////

              let myHeaders = new fetch.Headers();
              myHeaders.append("Content-Type", "application/json");



              console.log("");
              console.log(data.nombre);
              console.log("token: ", data.token);

              console.log("");
              console.log(data.deuda);

              var contrapartida = "01000" + data.cedula + "0" + data.linea;
              //
              var raw = JSON.stringify({
                // tipoMensaje: "0200",
                //bitmapPrimario: "F01A040108008000",
                //bitmap1: "0000004000000000",
                //bitmap2: contrapartida,
                //bitmap3: "000101",
                //bitmap4: data.deuda,
                //bitmap12: time,
                //bitmap13: date,
                //bitmap15: date,
                //bitmap22: "WEB",
                //bitmap32: "03007",
                //bitmap37: re.data[0].batch,
                //bitmap49: "840",
                //bitmap90: data.deuda,

                tipoMensaje: "0200",
                bitmapPrimario: "F01A040108008000",
                bitmap1: "07FC004000000000",
                bitmap2: contrapartida,
                bitmap3: "000101",
                bitmap4: data.deuda,
                bitmap12: hora_abitmedia,
                bitmap13: fecha_abitmedia,
                bitmap15: fecha_contable_abitmedia, // fecha contable
                bitmap22: "BTP",
                bitmap32: "03007",
                bitmap37: re.data[0].auth_code,
                bitmap49: "840",
                bitmap70: re.data[0].batch,
                bitmap71: veruficatamano(String(re.data[0].amount)),
                bitmap72: veruficatamano(String(re.data[0].card_brand)),
                bitmap73: veruficatamano(String(re.data[0].bin)),
                bitmap74: veruficatamano(String(re.data[0].network)),
                bitmap75: veruficatamano(String(re.data[0].acquirer_code)),
                //bitmap76: veruficatamano(String(re.data[0].acquirer)),
                bitmap76: "05BANCO",
                bitmap77: veruficatamano(String(re.data[0].payment_date)),
                bitmap78: "24LYigaApp",
                bitmap90: data.deuda,
              });
              var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
              };
              console.log('apitransaccionarTC')
              return fetch(
                "http://45.236.141.201:8085/iTransWService/g2c/rest/yiga5/transaccionarTC",
                requestOptions
              )
                .then((response) => response.text())
                .then((result) => {
                  console.log("enviado", raw);
                  console.log("RESULT", result);
                  const id = `${data.nombre} ${data.token}`;
                  db.collection("abitmedia_tokens")
                    .doc(id)
                    .update({
                      estado: 2,
                      lote: re.data[0].batch,
                      autorizacion: re.data[0].auth_code,
                    })
                    .then(() => {
                      if (data.idservicio) {
                        let myHeaders = new fetch.Headers();
                        myHeaders.append("Content-Type", "application/json");
                        let requestOptions = {
                          method: "GET",
                          headers: myHeaders,
                        };
                        return fetch(
                          `http://serverbd.intercommerce.com.ec/apiEscribirusarios/?consulta_servicioid=${data.idservicio}`,
                          requestOptions
                        )
                          .then((response) => {
                            // Primero, verificar si la respuesta de la API es exitosa
                            if (!response.ok) {
                              throw new Error("La solicitud a la API falló");
                            }

                            return response.json();
                          })
                          .then((estadoCliente) => {
                            // Aquí ya tienes acceso a los datos devueltos por la API

                            console.log("Datos recibidos:", estadoCliente);

                            const campoString = estadoCliente[0].estado.toString();

                            // Realizar la validación del estado
                            if (campoString == "ACTIVO") {
                              console.log("El estado es ACTIVO");
                              db.collection(
                                `users/${data.cedula}/yigapuntos/`
                              )
                                .get()
                                .then((querySnapshot) => {
                                  querySnapshot.forEach((doc) => {
                                    if (!querySnapshot.empty) {
                                      let data2 = doc.data();
                                      console.log(
                                        "CEDULA:",
                                        data.cedula,
                                        "IDSERVICIO:",
                                        data.idservicio
                                      );
                                      //
                                      let puntos = 150;
                                      let costo = data.deuda_;

                                      console.log("COSTO", costo);
                                      if (costo >= 7.5 && costo < 15.68) {
                                        puntos *= 0.25;
                                      }
                                      if (costo >= 15.68 && costo < 23.4) {
                                        puntos *= 0.5;
                                      }
                                      if (costo >= 23.4 && costo < 26.9) {
                                        puntos *= 0.75;
                                      }
                                      if (costo >= 26.9 && costo < 31.9) {
                                        puntos *= 1;
                                      }
                                      if (costo >= 31.9 && costo < 49.9) {
                                        puntos *= 1.25;
                                      }
                                      if (costo >= 49.9) {
                                        puntos *= 1.5;
                                      } else {
                                        puntos = puntos;
                                      }

                                      console.log("PUNTOS", puntos);
                                      let puntosTotal =
                                        Number(data2.puntos) + Number(puntos);
                                      db.collection(
                                        `users/${data.cedula}/yigapuntos/`
                                      )
                                        .doc(`${data.idservicio}`)
                                        .update({
                                          puntos: puntosTotal,
                                        })
                                        .then(() => {
                                          console.log("SE AGREGO PUNTOS");
                                        })
                                        .catch((error) => {
                                          console.log("ERROR 0", error);
                                        });
                                      db.collection(
                                        `users/${data.cedula}/yigapuntos/${data.idservicio}/historico`
                                      ).add({
                                        idservicio: data.idservicio,
                                        puntos: puntos,
                                        descripcion: "PAGO A TIEMPO",
                                        created:
                                          firebase.firestore.FieldValue.serverTimestamp(),
                                      });
                                      console.log(doc.id, " => ", doc.data());
                                    }
                                  });
                                });
                            } else {
                              console.log("El estado no es ACTIVO");
                            }
                          })
                          .catch((error) => {
                            // Manejo de cualquier error que ocurra durante la petición o el procesamiento de los datos
                            console.error(
                              "Error durante la petición o el procesamiento de datos:",
                              error
                            );
                          });
                      }
                    })
                    .catch((error) => {
                      db.collection("logs").add({
                        message: `No se encontro el id ${data.nombre} ${data.token}`,
                        created: firebase.firestore.FieldValue.serverTimestamp(),
                        function: "CRON ABITMEDIA",
                        usuario: "24ENLINEA",
                      });
                    });
                  console.log(data.nombre);
                  console.log("PAGO");
                  console.log("token: ", data.token);
                  console.log(" ");
                })
                .catch((error) => {
                  db.collection("logs").add({
                    message: `API ITRANS TRANSACCIONAR ERROR ${data.nombre} ${data.token}`,
                    error: error + "",
                    created: firebase.firestore.FieldValue.serverTimestamp(),
                    function: "CRON ABITMEDIA",
                    usuario: "24ENLINEA",
                  });
                  console.log("error itrans trasaccionar", error);
                });
            }
            if (re.message === "Solicitud de pago no encontrada") {
              console.log(data.nombre);
              console.log("NO SE ENCONTRO TOKEN");
              console.log("token: ", data.token);
              console.log(" ");
            }
            if (re.message === "No se encontró pago para la transacción") {
              console.log(data.nombre);
              console.log("NO ESTA PAGADO");
              console.log("token: ", data.token);
              console.log(" ");
            }
            // }).catch((error) => {
            //   console.log(error)
            // });
          
        }
        await delay(5000); // Espera 30 segundos
      }
    } catch (error) {
      console.error("Error obteniendo documentos:", error);
    } finally {

    }
  }
  return (
    <Grid
      container
      component="main"
      alignItems="center"
      justifyContent="flex-start"
      style={{ height: '100vh' }}
    >
      <Grid
        item
        xs={false}
        sm={false}
        md={5}
        style={{
          textAlign: "center"
        }}
      >
        <Box sx={{ margin: 'auto' }}>
          <Hidden mdDown>
            <Box style={{ backgroundImage: 'linear-gradient(130deg, #3C2180 0, #3C2180 20%, #5B0081 75%)', height: '100vh' }}>
              <CardContent>
                <Box p={5}>
                  <Box pt={5}>
                    <img width="25%" alt='yiga5.com' src={logo} />
                  </Box>
                  <Box p={2}>
                    <img src={img} width="30%" alt='' />
                  </Box>
                  <Grid container spacing={2}>
                    <Grid item md={6}>
                      <Card style={{ backgroundColor: '#7635dc' }}>
                        <Box p={1}>
                          <Box component={RouterLink} to='/contratar'
                            style={{ textAlign: 'center', color: 'white', textDecoration: 'none' }} >
                            <Typography variant="body2" align='center' color='white' >       <IconButton>
                              <SupportAgentIcon style={{ color: 'white ' }} />
                            </IconButton><strong>Deseo contratar</strong> </Typography>
                          </Box>
                        </Box>
                      </Card>
                    </Grid>
                    <Grid item md={6}>
                      <Card style={{ backgroundColor: '#7635dc' }}>
                        <Box p={1}>
                          <Box
                            component={RouterLink} to='/pago'
                            style={{ textAlign: 'center', color: 'white', textDecoration: 'none' }} >
                            <Typography variant="body2" align='center' color='white' >       <IconButton>
                              <PaymentIcon style={{ color: 'white ' }} />
                            </IconButton><strong>Paga tus servicios y facturas</strong> </Typography>
                          </Box>
                        </Box>
                      </Card>
                    </Grid>
                  </Grid>
                  <Box pt={4} pb={4}>
                    <Card >
                      <Box p={1}>
                        <Box component={RouterLink} to='/metaverso'
                          style={{ textAlign: 'center', textDecoration: 'none' }} >
                          <Typography variant="body2" align='center'  >       <IconButton>
                            <EmojiPeopleIcon />
                          </IconButton><strong>Unete al Metaverso</strong> </Typography>
                        </Box>
                      </Box>
                    </Card>
                  </Box>
                  <Box py={2} style={{ textAlign: 'center', alignItems: 'center' }}>
                    <Box style={{ textAlign: 'center', alignItems: 'center' }}>
                      <Typography color='white' variant='h6'>¡Lleva tu Oficina Móvil donde quieras! DESCARGA LA APP</Typography>
                      <img src={y5} width="25%" alt='' />
                    </Box>
                  </Box>

                  <Box style={{ textAlign: 'center', alignItems: 'center' }}>
                    <a target="_blank" href="https://play.google.com/store/apps/details?id=com.intercommerce.yigamovil">
                      <img src={disaponible} width="20%" />
                    </a>
                    <a target="_blank" href='https://apps.apple.com/ec/app/yiga5-experience/id1666577435'>
                      <img src={proximamente} width="20%" />
                    </a>
                  </Box>
                </Box>
              </CardContent>
            </Box>
          </Hidden>
        </Box>
      </Grid>
      <Grid>
      </Grid>
      <ThemeProvider theme={theme}>
        <Grid
          item
          xs={12}
          sm={12}
          md={7}
        >
          <Paper
            sx={{
              backgroundColor: 'transparent',
              color: '#fff',
              width: '100%',
              height: '100vh',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              //backgroundImage: `url(https://source.unsplash.com/1600x1000/?beach)`,
              backgroundImage: `url(${img_back})`,
            }}
          >
            <Box pt={5}></Box>
            <Container maxWidth="md" sx={{ mb: 4 }}>
              <Card style={{ backgroundColor: '#fff', color: 'black' }}>
                <CardContent>
                  <Container maxWidth="md" >

                    {errorCreate ?
                      <Box py={2}>
                        <Alert severity="error">
                          {errorCreate.code == 'auth/wrong-password' && 'La contraseña no es válida.'}
                          {errorCreate.code == 'auth/too-many-requests' && 'El acceso a esta cuenta se ha deshabilitado temporalmente... configurando su contraseña o puede volver a intentarlo más tarde.'}
                          {errorCreate.code == 'auth/user-not-found' && 'No existe registro de usuario correspondiente a este identificador.'}
                          {errorCreate.code == 'not-found-client' && errorCreate.message}
                        </Alert>
                      </Box> :
                      <Box style={{ height: 80 }}></Box>
                    }
               
                    <Typography color='primary' variant='h4' component='h1' gutterBottom>
                      <strong>¡Hola, Bienvenido de nuevo!</strong>
                    </Typography>

                    <form onSubmit={submitHandler}>
                      <Box pt={2} pb={1}>
                        <InputValidation
                          estado={email}
                          cambiarEstado={setEmail}
                          label="Numero de cedula"
                          name="email"
                        //helperText="Sólo correos asociados a la institución '@ups.edu.ec'."
                        //patterns={/^[a-zA-Z0-9_.+-]+@+(est\.)?(ups.edu.ec)/}
                        //disabled={loading}
                        />
                      </Box>
                      <Box pb={1}>
                        <InputValidation
                          estado={password}
                          cambiarEstado={setPassword}
                          type="password"
                          label="Contraseña"
                          name="password"
                          helperText="Minimo 8 caracteres"
                          patterns={/^.{8,30}$/}
                        //disabled={loading}
                        />
                      </Box>

                      <Box pb={3}>
                        <LoadingButton
                          fullWidth
                          size='large'
                          loading={loading}
                          disabled={!email.campo || !password.campo || loading}
                          variant="contained"
                          type="submit"

                        >
                          Iniciar sesión
                        </LoadingButton>
                      </Box>
                    </form>
                    {/* <button onClick={updateUser}>Hola</button> */}
                  </Container>
                </CardContent>
              </Card>
            </Container >
            <Container
              maxWidth="md" sx={{ mb: 4 }}>
              <Card style={{ backgroundColor: '#fff', color: 'black' }}>
                <CardContent>
                  <Grid container spacing={4}>
                    <Grid item md={6} xs={12}>
                      <Card
                        style={{ backgroundColor: '#682F8D' }}
                      >
                        <CardContent component={RouterLink} to='/accounts/reset-password/'
                          style={{ textAlign: 'center', color: 'white', textDecoration: 'none' }}>
                          <Box>
                            <IconButton>
                              <LockIcon style={{ color: 'white' }} />
                            </IconButton>
                            <Typography align='center' variant='body2'>¿Has olvidado tu contraseña?</Typography>
                            <Typography align='center' variant='body2'>Cambialo ahora</Typography>
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Card style={{ backgroundColor: '#682F8D' }}>
                        <CardContent component={RouterLink} to='/accounts/signup/'
                          style={{ textAlign: 'center', color: 'white', textDecoration: 'none' }} >
                          <Box>
                            <IconButton>
                              <EmojiPeopleIcon style={{ color: 'white' }} />
                            </IconButton>
                            <Typography align='center' variant='body2'>¿Usuario nuevo?</Typography>
                            <Typography align='center' variant='body2'>Registrate ahora</Typography>
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                  <Hidden smUp>

                    <Box pt={4} pb={4}>
                      <Card style={{ backgroundColor: '#7635dc' }}>
                        <Box p={1}>
                          <Box component={RouterLink} to='/contratar'
                            style={{ textAlign: 'center', color: 'white', textDecoration: 'none' }} >
                            <Typography variant="body2" align='center' color='white' >       <IconButton>
                              <SupportAgentIcon style={{ color: 'white ' }} />
                            </IconButton><strong>Deseo contratar</strong> </Typography>
                          </Box>
                        </Box>
                      </Card>
                    </Box>
                    <Card style={{ backgroundColor: '#7635dc' }}>
                      <Box p={1}>
                        <Box component={RouterLink} to='/pago'
                          style={{ textAlign: 'center', color: 'white', textDecoration: 'none' }} >
                          <Typography variant="body2" align='center' color='white' >       <IconButton>
                            <PaymentIcon style={{ color: 'white ' }} />
                          </IconButton><strong>Paga tus servicios y facturas</strong> </Typography>
                        </Box>
                      </Box>
                    </Card>
                  </Hidden>
                  <Box pt={2}>
                    <Card style={{ backgroundColor: '#7635dc' }}>
                      <Box p={1}>
                        <Box component={RouterLink} to='/tickets'
                          style={{ textAlign: 'center', color: 'white', textDecoration: 'none' }} >
                          <Typography variant="body2" align='center' color='white' >       <IconButton>
                            <PaymentIcon style={{ color: 'white ' }} />
                          </IconButton><strong>Abre un Ticket</strong> </Typography>
                        </Box>
                      </Box>
                    </Card>
                  </Box>
                </CardContent>
              </Card>
            </Container>
          </Paper>
        </Grid>
      </ThemeProvider>
    </Grid>
  )
}