import React, { useState, useRef, useEffect } from 'react';

import InputValidation from '../../components/utils/InputValidation';
import logo from '../../assets/img/logo_yiga_blanco.png'
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../redux/actions/userActions'
import moment from 'moment';
import { auth, db, functions } from '../../db/firebase'
import { Container, Card, CardContent, Typography, Paper, Hidden, Grid, Alert, Box } from '@mui/material';
import img from '../../assets/img/eli.png';


import y5 from '../../assets/img/y5.png'

import proximamente from '../../assets/img/proximamente.png';
import disaponible from '../../assets/img/disaponible.png'
import img_back from '../../assets/img/fondo.png';
import PaymentIcon from '@mui/icons-material/Payment';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import IconButton from '@mui/material/IconButton';
import LockIcon from '@mui/icons-material/Lock';

import LoadingButton from '@mui/lab/LoadingButton';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { useTheme, ThemeProvider, createTheme } from '@mui/material/styles';
export const LoginPage = () => {
  const [email, setEmail] = useState({ campo: '', valido: true })
  const [password, setPassword] = useState({ campo: '', valido: null })
  const userLogin = useSelector(state => state.userLogin)
  const { error: errorCreate, loading } = userLogin
  //
  const userSignup = useSelector(state => state.userSignup)
  const { signup } = userSignup

  //
  const dispatch = useDispatch()
  useEffect(() => {
 

 
  
  }, [loading])
  const submitHandler = (e) => {
    e.preventDefault()
    try {
      if (email.valido === true && password.valido) {
        dispatch(login({
          'dni': email.campo,
          'password': password.campo,
        }))
      }

    } catch (error) {
    }
  }
  const test = () => {
    var current = new Date();
    //De la fecha actual le sumamos 2 dia y 
    //le enviamos la notificacion 2 dias antes de su fecha corte
    var startfulldate = moment(new Date(current.getTime() + 172800000)).format("YYYY-MM-DD")
    let ref = db.collection("users").where('fecha_corte', '==', startfulldate).get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {

          const data = doc.data()
          console.log('cedula: ', data.displayName, ' token: ', data.token)
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
  }
  const updateUser = (e) => {
    console.log('a')
    const data = {
      cargo: 0,
      email: 'celinxiomara@gmail.com',
      departamento: 0,
      rol: 0,
    }
    const createUser = functions.httpsCallable('updateUser')
    createUser(data)
      .then(res => {
        console.log('Se actualizo usuario: ', res)
      })
      .catch(error => console.log(error))


  }
  let theme = createTheme({
    palette: {

      background: {
        default: '#1d0226',
        paper: '#2D033B',
      },
      primary: {
        main: '#7635DC'
      },
      secondary: {
        main: '#6d1b7b',
      },

      //},
      // typography: {
      //     button: {
      //     textTransform: "none"
      //}
    },
    components: {
      // Name of the component

      MuiButton: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            borderRadius: '1rem',
            textTransform: 'none',
            fontSize: '1rem',
            boxShadow: '0 8px 20px -12px rgba(0,0,0,0.5)',
          },
        },
      },

      MuiCard: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            boxShadow: '0 8px 20px -12px rgba(0,0,0,0.3)',
            borderRadius: '1rem',
          },
        },
      },
    },
  })
  
  const load1 = () => {
    const data_data = []
    var i = 0
    setInterval(() => {
      const item = data_data[i++]
      if (item.correo) {

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({
          "correo": item.correo
        });
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };

        fetch("https://us-central1-sistema-gestion-intercommerce.cloudfunctions.net/api/email", requestOptions)
          .then(response => response.text())
          .then(result => {
            console.log(item.nombre, ' ', item.correo, ' CORREO ENVIADO')

          })
          .catch(error => console.log('error', error));

        // var myHeaders = new Headers()
        // myHeaders.append("Content-Type", "application/json")
        // var raw = JSON.stringify({
        //   "cedula":  `${item.cedula}`,
        //   "nombres": item.nombre,
        //   "direccion": item.direccion_principal,
        //   "provincia": "null",
        //   "canton": "null",
        //   "sector": "null",
        //   "zona": "null",
        //   "parroquia": "null",
        //   "coordenadas": "null",
        //   "telefono": item.telefono ? `${item.telefono}` : 'null',
        //   "celular": item.movil ? `${item.movil}` : 'null',
        //   "whatapp": "null",
        //   "numReferencia": "null",
        //   "estado": item.estado,
        //   "cuentabancaria": "null",
        //   "banco": "null",
        //   "tipobanco": "null",
        //   "fechanacimiento": "null",
        //   "accededebitobancario": "null",
        //   "codigopago": item.codigo_pago,
        //   "gradodiscapacidad": "null",
        //   "gradodiscapacitado": "null",
        //   "numContrato": item.NUM_CONTRATO,
        //   "obtieneCamara": "null",
        //   "cantidadCamara": "null",
        //   "correo":  item.correo ? item.correo : 'null',
        //   "vendedor": item.AGENTE_VENDEDOR,
        //   "idmikrowisp": item.id,
        // })
        // var requestOptions = {
        //   method: 'POST',
        //   headers: myHeaders,
        //   body: raw
        // }
        // fetch("http://192.168.90.24:3000/crearCliente", requestOptions)
        //   .then(response => response.text())
        //   .then(result => {
        //     console.log(item.nombre, ` `, item.NUM_CONTRATO, ' ',item.estado,' INGRESADO A SOEI')
        //     console.log(result)
        //   })
        //   .catch(error => console.log('error', error))
      }
    }, 500)

    // var myHeaders = new Headers();
    // myHeaders.append("Content-Type", "application/json");

    // var raw = JSON.stringify({
    //   "cedula": "953416270",
    //   "nombres": "SEBASTIAN MITE TORRES",
    //   "direccion": "111",
    //   "provincia": "11",
    //   "canton": "111",
    //   "sector": "111",
    //   "zona": "111",
    //   "parroquia": "111",
    //   "coordenadas": "111",
    //   "telefono": "111",
    //   "celular": "111",
    //   "whatapp": "111",
    //   "numReferencia": "111",
    //   "estado": "111",
    //   "cuentabancaria": "1111",
    //   "banco": "111",
    //   "tipobanco": "111",
    //   "fechanacimiento": "111",
    //   "accededebitobancario": "111",
    //   "codigopago": "10000s",
    //   "gradodiscapacidad": "111",
    //   "gradodiscapacitado": "111",
    //   "numContrato": "111",
    //   "obtieneCamara": "111",
    //   "cantidadCamara": "1111",
    //   "email": "lianmejia@gmail.com",
    //   "vendedor": "Sebastian Mite"
    // });

    // var requestOptions = {
    //   method: 'POST',
    //   headers: myHeaders,
    //   body: raw,
    //   redirect: 'follow'
    // };

    // fetch("http://192.168.90.24:3000/crearCliente", requestOptions)
    //   .then(response => response.text())
    //   .then(result => console.log(result))
    //   .catch(error => console.log('error', error));

  }
  return (
    <Grid
      container
      component="main"
      alignItems="center"
      justifyContent="flex-start"
      style={{ height: '100vh' }}
    >
      <Grid
        item
        xs={false}
        sm={false}
        md={5}
        style={{
          textAlign: "center"
        }}
      >
        <Box sx={{ margin: 'auto' }}>
          <Hidden mdDown>
            <Box style={{ backgroundImage: 'linear-gradient(130deg, #3C2180 0, #3C2180 20%, #5B0081 75%)', height: '100vh' }}>
              <CardContent>
                <Box p={5}>
                  <Box pt={5}>
                    <img width="25%" alt='yiga5.com' src={logo} />
                  </Box>
                  <Box p={2}>
                    <img src={img} width="30%" alt='' />
                  </Box>
                  <Grid container spacing={2}>
                    <Grid item md={6}>
                      <Card style={{ backgroundColor: '#7635dc' }}>
                        <Box p={1}>
                          <Box component={RouterLink} to='/contratar'
                            style={{ textAlign: 'center', color: 'white', textDecoration: 'none' }} >
                            <Typography variant="body2" align='center' color='white' >       <IconButton>
                              <SupportAgentIcon style={{ color: 'white ' }} />
                            </IconButton><strong>Deseo contratar</strong> </Typography>
                          </Box>
                        </Box>
                      </Card>
                    </Grid>
                    <Grid item md={6}>
                      <Card style={{ backgroundColor: '#7635dc' }}>
                        <Box p={1}>
                          <Box
                            component={RouterLink} to='/pago'
                            style={{ textAlign: 'center', color: 'white', textDecoration: 'none' }} >
                            <Typography variant="body2" align='center' color='white' >       <IconButton>
                              <PaymentIcon style={{ color: 'white ' }} />
                            </IconButton><strong>Paga tus servicios y facturas</strong> </Typography>
                          </Box>
                        </Box>
                      </Card>
                    </Grid>
                  </Grid>
                  <Box pt={4} pb={4}>
                      <Card >
                        <Box p={1}>
                          <Box component={RouterLink} to='/metaverso'
                            style={{ textAlign: 'center',  textDecoration: 'none' }} >
                            <Typography variant="body2" align='center'  >       <IconButton>
                              <EmojiPeopleIcon />
                            </IconButton><strong>Unete al Metaverso</strong> </Typography>
                          </Box>
                        </Box>
                      </Card>
                    </Box>
                  <Box py={2} style={{ textAlign: 'center', alignItems: 'center' }}>
                    <Box style={{ textAlign: 'center', alignItems: 'center' }}>
                      <Typography color='white' variant='h6'>¡Lleva tu Oficina Móvil donde quieras! DESCARGA LA APP</Typography>
                      <img src={y5} width="25%" alt='' />
                    </Box>
                  </Box>
                  <Box style={{ textAlign: 'center', alignItems: 'center' }}>
                    <a target="_blank" href="https://play.google.com/store/apps/details?id=com.intercommerce.yigamovil">
                      <img src={disaponible} width="20%" />
                    </a>
                    <a target="_blank" href='https://apps.apple.com/ec/app/yiga5-experience/id1666577435'>
                      <img src={proximamente} width="20%" />
                    </a>
                  </Box>
                </Box>
              </CardContent>
            </Box>
          </Hidden>
        </Box>
      </Grid>
      <Grid>
      </Grid>
      <ThemeProvider theme={theme}>
        <Grid
          item
          xs={12}
          sm={12}
          md={7}
        >
          <Paper
            sx={{
              backgroundColor: 'transparent',
              color: '#fff',
              width: '100%',
              height: '100vh',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              //backgroundImage: `url(https://source.unsplash.com/1600x1000/?beach)`,
              backgroundImage: `url(${img_back})`,
            }}
          >
            <Box pt={5}></Box>
            <Container maxWidth="md" sx={{ mb: 4 }}>
              <Card style={{ backgroundColor: '#fff', color: 'black' }}>
                <CardContent>
                  <Container maxWidth="md" >

                    {errorCreate ?
                      <Box py={2}>
                        <Alert severity="error">
                          {errorCreate.code == 'auth/wrong-password' && 'La contraseña no es válida.'}
                          {errorCreate.code == 'auth/too-many-requests' && 'El acceso a esta cuenta se ha deshabilitado temporalmente... configurando su contraseña o puede volver a intentarlo más tarde.'}
                          {errorCreate.code == 'auth/user-not-found' && 'No existe registro de usuario correspondiente a este identificador.'}
                          {errorCreate.code == 'not-found-client' && errorCreate.message}
                        </Alert>
                      </Box> :
                      <Box style={{ height: 80 }}></Box>
                    }
                    <Typography color='primary' variant='h4' component='h1' gutterBottom>
                      <strong>¡Hola, Bienvenido de nuevo!</strong>
                    </Typography>

                    <form onSubmit={submitHandler}>
                      <Box pt={2} pb={1}>
                        <InputValidation
                          estado={email}
                          cambiarEstado={setEmail}
                          label="Numero de cedula"
                          name="email"
                        //helperText="Sólo correos asociados a la institución '@ups.edu.ec'."
                        //patterns={/^[a-zA-Z0-9_.+-]+@+(est\.)?(ups.edu.ec)/}
                        //disabled={loading}
                        />
                      </Box>
                      <Box pb={1}>
                        <InputValidation
                          estado={password}
                          cambiarEstado={setPassword}
                          type="password"
                          label="Contraseña"
                          name="password"
                          helperText="Minimo 8 caracteres"
                          patterns={/^.{8,30}$/}
                        //disabled={loading}
                        />
                      </Box>

                      <Box pb={3}>
                        <LoadingButton
                          fullWidth
                          size='large'
                          loading={loading}
                          disabled={!email.campo || !password.campo || loading}
                          variant="contained"
                          type="submit"

                        >
                          Iniciar sesión
                        </LoadingButton>
                      </Box>
                    </form>
                    {/* <button onClick={updateUser}>Hola</button> */}
                  </Container>
                </CardContent>
              </Card>
            </Container >
            <Container
              maxWidth="md" sx={{ mb: 4 }}>
              <Card style={{ backgroundColor: '#fff', color: 'black' }}>
                <CardContent>
                  <Grid container spacing={4}>
                    <Grid item md={6} xs={12}>
                      <Card
                        style={{ backgroundColor: '#682F8D' }}
                      >
                        <CardContent component={RouterLink} to='/accounts/reset-password/'
                          style={{ textAlign: 'center', color: 'white', textDecoration: 'none' }}>
                          <Box>
                            <IconButton>
                              <LockIcon style={{ color: 'white' }} />
                            </IconButton>
                            <Typography align='center' variant='body2'>¿Has olvidado tu contraseña?</Typography>
                            <Typography align='center' variant='body2'>Cambialo ahora</Typography>
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Card style={{ backgroundColor: '#682F8D' }}>
                        <CardContent component={RouterLink} to='/accounts/signup/'
                          style={{ textAlign: 'center', color: 'white', textDecoration: 'none' }} >
                          <Box>
                            <IconButton>
                              <EmojiPeopleIcon style={{ color: 'white' }} />
                            </IconButton>
                            <Typography align='center' variant='body2'>¿Usuario nuevo?</Typography>
                            <Typography align='center' variant='body2'>Registrate ahora</Typography>
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                  <Hidden smUp>
                    
                    <Box pt={4} pb={4}>
                      <Card style={{ backgroundColor: '#7635dc' }}>
                        <Box p={1}>
                          <Box component={RouterLink} to='/contratar'
                            style={{ textAlign: 'center', color: 'white', textDecoration: 'none' }} >
                            <Typography variant="body2" align='center' color='white' >       <IconButton>
                              <SupportAgentIcon style={{ color: 'white ' }} />
                            </IconButton><strong>Deseo contratar</strong> </Typography>
                          </Box>
                        </Box>
                      </Card>
                    </Box>
                    <Card style={{ backgroundColor: '#7635dc' }}>
                      <Box p={1}>
                        <Box component={RouterLink} to='/pago'
                          style={{ textAlign: 'center', color: 'white', textDecoration: 'none' }} >
                          <Typography variant="body2" align='center' color='white' >       <IconButton>
                            <PaymentIcon style={{ color: 'white ' }} />
                          </IconButton><strong>Paga tus servicios y facturas</strong> </Typography>
                        </Box>
                      </Box>
                    </Card>
                  </Hidden>
                  <Box pt={2}>
                    <Card style={{ backgroundColor: '#7635dc' }}>
                      <Box p={1}>
                        <Box component={RouterLink} to='/tickets'
                          style={{ textAlign: 'center', color: 'white', textDecoration: 'none' }} >
                          <Typography variant="body2" align='center' color='white' >       <IconButton>
                            <PaymentIcon style={{ color: 'white ' }} />
                          </IconButton><strong>Abre un Ticket</strong> </Typography>
                        </Box>
                      </Box>
                    </Card>
                  </Box>
                </CardContent>
              </Card>
            </Container>
          </Paper>
        </Grid>
      </ThemeProvider>
    </Grid>
  )
}