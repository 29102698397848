
import React, { useState, useEffect, useCallback } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
export const MetaversoPage = () => {
  const { unityProvider, isLoaded, loadingProgression } = useUnityContext({
    loaderUrl: `${process.env.PUBLIC_URL}/build/unity.loader.js`,
    dataUrl: `${process.env.PUBLIC_URL}/build/unity.data`,
    frameworkUrl: `${process.env.PUBLIC_URL}/build/unity.framework.js`,
    codeUrl: `${process.env.PUBLIC_URL}/build/unity.wasm`,
  });
  // We'll use a state to store the device pixel ratio.
  const [devicePixelRatio, setDevicePixelRatio] = useState(
    window.devicePixelRatio
  );
  const loadingPercentage = Math.round(loadingProgression * 100);
  const handleChangePixelRatio = useCallback(
    function () {
      // A function which will update the device pixel ratio of the Unity
      // Application to match the device pixel ratio of the browser.
      const updateDevicePixelRatio = function () {
        setDevicePixelRatio(window.devicePixelRatio);
      };
      // A media matcher which watches for changes in the device pixel ratio.
      const mediaMatcher = window.matchMedia(
        `screen and (resolution: ${devicePixelRatio}dppx)`
      );
      // Adding an event listener to the media matcher which will update the
      // device pixel ratio of the Unity Application when the device pixel
      // ratio changes.
      mediaMatcher.addEventListener("change", updateDevicePixelRatio);
      return function () {
        // Removing the event listener when the component unmounts.
        mediaMatcher.removeEventListener("change", updateDevicePixelRatio);
      };
    },
    [devicePixelRatio]
  );
  return (
    <div>
      {/* <img src={`${process.env.PUBLIC_URL}/logo192.png`} width="10%" /> */}

      {isLoaded === false && (
        // We'll conditionally render the loading overlay if the Unity
        // Application is not loaded.
        <div style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          padding: '20px',
          textAlign: 'center'
        }}>
          <div>
            <h1>Entrando al Metaverso... {loadingPercentage}%</h1>
            <p>Estamos preparando tu experiencia en el metaverso, ¡un momento mientras cargamos todo lo increíble que te espera!</p>
          </div>

        </div>
      )}
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          padding: '20px',
          textAlign: 'center'
        }}>
      </div>
      <Unity
        className="unity"
        style={{ width: 900, height: 900 }}
        devicePixelRatio={devicePixelRatio} unityProvider={unityProvider} />

    </div>
  )
}