import React, { useState, useEffect } from 'react';
import {
    AppBar, Box, Toolbar, Divider, Drawer,
    IconButton, ListItem, List, ListItemIcon, ListItemText, Tooltip, Typography
} from '@mui/material/';
import logo from '../../assets/img/yiga5_white.png'

import logo24enlinea from '../../assets/img/logoluz.gif'
import { Link as RouterLink } from 'react-router-dom';
import { auth, db } from '../../db/firebase';
import { useLocation } from 'react-router-dom'
import { Link, useNavigate } from 'react-router-dom'
import { ThemeProvider, useTheme, styled, createTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import MenuUser from './MenuUser';
import { useDispatch, useSelector } from 'react-redux';
import { SelectIcons } from '../utils/SelectIcons';
import LocalGroceryStoreOutlinedIcon from '@mui/icons-material/LocalGroceryStoreOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import items from './items.json'
import { logout } from '../../redux/actions/userActions';
import { ButtonNotifications } from '../account/notifications/ButtonNotifications';
const drawerWidth = 300;
//
export const Navbar = (props) => {
    const dispatch = useDispatch();
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    let navigate = useNavigate()
    const location = useLocation();
    const { window } = props;
    const [email, setEmail] = useState();
    const [puntos, setPuntos] = useState(0);
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen)
    }
    let theme = createTheme({
        palette: {
            mode: 'dark',
        },
        // components: {
        //     MuiDrawer: {
        //         styleOverrides: {
        //             paper: {
        //                 backgroundColor: "#333",
        //                 color: "white",
        //                 boxSizing: "border-box"
        //             }
        //         }
        //     }
        // }
    })
    useEffect(() => {
        auth.onAuthStateChanged(userAuth => {
            if (userAuth) {
                setEmail(userAuth.email)
            }
            //fetchData()
        });
    }, [])
    const fetchData = async () => {
        const querySnapshot = await db.collection(`users/${userInfo.uid}/yigapuntos/${userInfo.servicios.id}/historico`).get()
        const data = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        setPuntos(data)
        console.log('hola')
        console.log(data, 'hola')
        //
        await db.collection(`users/${userInfo.uid}/yigapuntos/`).doc(`${userInfo.servicios.id}`).onSnapshot((doc) => {
            let data = doc.data()
            setPuntos(data)
        })
    }
    const drawer = (
        <>
            <ThemeProvider theme={theme}>
                <Box pt={5} display="flex" >
                    <img width="100%" src={logo24enlinea}></img>
                </Box>
                <List>
                    <Box px={1}>
                        <Divider />
                        <Box py={2}>
                            {
                                items.map((item, index) => (
                                    <ListItem
                                        style={{ borderRadius: 12, margin: '0px 0px 4px' }}
                                        key={index}
                                        component={RouterLink}
                                        to={item.link}
                                        selected={location.pathname === item.link}
                                        button>
                                        <ListItemIcon> <SelectIcons key={index} item={index} /> </ListItemIcon>
                                        <ListItemText primary={item.name} />
                                    </ListItem>
                                ))
                            }
                        </Box>
                        <Divider />
                        <Box py={2}>
                            <ListItem button onClick={() => dispatch(logout())} style={{ borderRadius: 12, margin: "0px 0px 4px" }} >
                                <ListItemIcon ><LogoutIcon style={{ color: '#00B2FF' }} /></ListItemIcon>
                                <ListItemText primary="Cerrar sesión" />
                            </ListItem>
                        </Box>
                        <Toolbar style={{ justifyContent: 'center' }}>
                            <img src={logo} width="50%" />
                        </Toolbar>
                    </Box>
                </List>
            </ThemeProvider>
        </>
    );
    const container = window !== undefined ? () => window().document.body : undefined;
    return (
        <>
            <Box sx={{ display: 'flex' }}>
                {props.isAuth === true ?
                    <>
                        <AppBar
                            position='absolute'
                            elevation={0}
                            color='inherit'
                            sx={{
                                zIndex: 1,
                                width: { sm: `calc(100% - ${drawerWidth}px)` },
                                ml: { sm: `${drawerWidth}px` },
                            }}
                        >
                            <Toolbar>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                    onClick={handleDrawerToggle}
                                    sx={{ mr: 2, display: { sm: 'none' } }}
                                >
                                    <MenuIcon />
                                </IconButton>
                                <div style={{ flexGrow: 1 }} >
                                </div>
                                {/* <Box display="flex" >
                                    <img width={100} src={logo}></img>
                                </Box> */}
                                
                                <ButtonNotifications />
                                <MenuUser props={userInfo} />
                                {/* <Button onClick={logoutHandler} variant="contained" color="primary" size="small">Cerrar sesión</Button>  */}
                            </Toolbar>
                        </AppBar>
                        <Box
                            component="nav"
                            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 }, zIndex: 0 }}
                            aria-label="mailbox folders"
                        >
                            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                            <Drawer
                                container={container}
                                variant="temporary"
                                open={mobileOpen}
                                onClose={handleDrawerToggle}
                                ModalProps={{
                                    keepMounted: true, // Better open performance on mobile.
                                }}
                                sx={{
                                    display: { xs: 'block', sm: 'none' },
                                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                                }}
                            >
                                {drawer}
                            </Drawer>
                            <Drawer
                                variant="permanent"
                                sx={{
                                    display: { xs: 'none', sm: 'block' },
                                    '& .MuiDrawer-paper': { borderRight: '0px solid rgba(0, 0, 0, 0.12)', boxSizing: 'border-box', width: drawerWidth },
                                }}
                                open
                            >
                                {drawer}
                            </Drawer>
                        </Box>
                        <Box
                            component="main"
                            sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
                        >
                            <Toolbar />
                            {props.children}
                        </Box>
                    </> : props.children}
            </Box>
        </>
    )
}