import { useEffect, useState } from 'react';
import { Container, Box, Typography, Stack } from '@mui/material'
import { useSelector } from 'react-redux';
import 'react-credit-cards/es/styles-compiled.css'
import { firebase, db } from '../../db/firebase'

import moment from 'moment'
import { ButtonBack } from '../../components/utils/ButtonBack';
function validarDocumento(documento) {
    const longitud = documento.length;
    if (longitud === 10) {
        return '05';
    } else if (longitud === 13) {
        return '04';
    } else {
        return '05';
    }
}
export default function AddCardPage() {
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    const [loading, setLoading] = useState(false)
    const [url, setURL] = useState(null)
    const load_cards = async () => {
        setLoading(true)
        try {
            setLoading(true); // Mover aquí para asegurarse de que se ejecute antes de la solicitud fetch
            if (userInfo.cedula) {
                const tipoDocumento = validarDocumento(userInfo.cedula);
                var myHeaders = new Headers();
                myHeaders.append("Authorization", "Bearer mjwguxipbjg1iitxkjxb6tz6s3tl8f-rrgi371ad1sjkhgnrs0fcvvn8tkzworjbkcbsm");
                var raw = JSON.stringify({
                    "integration": true,
                    "third": {
                        "document": userInfo.cedula,
                        "document_type": tipoDocumento,
                        "name": userInfo.nombre,
                        "email": userInfo.correo,
                        "phones": userInfo.telefono,
                        "address": userInfo.direccion_principal,
                        "type": "Individual"
                    },
                    "notify_url": null,
                    "custom_value": null
                });

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
                let date = new Date()
                let formattedDate = moment(date).format('YYYY-MM-DD HH:mm:ss')
                const response = await fetch(`https://api.abitmedia.cloud/pagomedios/v2/cards/register`, requestOptions);
                const result = await response.text();
                var res = JSON.parse(result);
                var id = `${userInfo.nombre} ${userInfo.cedula}${userInfo.index_linea}`;
                setURL(res.data);
                console.log(result);

                // Agrega el parámetro 'data' para usarlo dentro de la función
                var myHeaders2 = new Headers(); // Asegúrate de que 'myHeaders' esté definido antes de usarlo
                myHeaders2.append("Authorization", "Bearer mjwguxipbjg1iitxkjxb6tz6s3tl8f-rrgi371ad1sjkhgnrs0fcvvn8tkzworjbkcbsm");
                var requestOptions2 = {
                    method: 'GET',
                    headers: myHeaders2,
                    redirect: 'follow'
                };
                fetch(`https://api.abitmedia.cloud/pagomedios/v2/cards?integration=true&document=${userInfo.cedula}`, requestOptions2)
                    .then(response => response.text()) // Usa response.json() para parsear la respuesta JSON
                    .then(result => {
                        var tarjeta = JSON.parse(result);
                        // No necesitas parsear 'result' ya que fetch() lo hace automáticamente
                        db.collection("abitmedia_debitoauto").doc(id).set({
                            nombre: userInfo.nombre,
                            linea: userInfo.index_linea,
                            estado: 2,
                            idservicio: userInfo.servicios.id,
                            cedula: userInfo.cedula,
                            fecha_generada: formattedDate,
                            created: firebase.firestore.FieldValue.serverTimestamp()
                        }, { merge: true });

                        console.log(tarjeta);                          // Aquí puedes hacer algo con 'tarjeta' si es necesario
                    })
                    .catch(error => console.log('error', error));



                setLoading(false);
                window.localStorage.clear();
                window.open(res.data.url, "mywindow", "width=900,height=900");
                window.localStorage.clear();



            }
        } catch (error) {
            setLoading(false);
            console.error('Error:', error);
        }
    }
    useEffect(() => {
        load_cards()
     
    }, [])

    return (
        <>
            <Container maxWidth='lx' disableGutters={window.innerWidth <= 767}>
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="flex-start"
                    spacing={5}>
                    <Box pl={2}>
                        <Typography variant='h3' gutterBottom component='h1'><strong>Agregar Pago</strong></Typography>
                        <Typography variant='body' gutterBottom component='p'>Tus datos de pago, transacciones, pagos periódicos.</Typography>
                    </Box>
                </Stack>
                <ButtonBack />
            </Container>
            {/* {loading === false ?
                <iframe
                    title="agregarpago"
                    frameborder={0}
                    width="100%"
                    height={1200}
                    src={url}>
                </iframe>
                : 'Cargando'} */}
        </>
    )
}