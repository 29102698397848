import React, { useState, useEffect } from 'react';
import HomeIcon from '@mui/icons-material/Home';
import StarsTwoToneIcon from '@mui/icons-material/StarsTwoTone';
import PlaceTwoToneIcon from '@mui/icons-material/PlaceTwoTone';
import RocketLaunchTwoToneIcon from '@mui/icons-material/RocketLaunchTwoTone';
import ReceiptTwoToneIcon from '@mui/icons-material/ReceiptTwoTone';
import NotificationsNoneTwoToneIcon from '@mui/icons-material/NotificationsNoneTwoTone';
import AssignmentTurnedInTwoToneIcon from '@mui/icons-material/AssignmentTurnedInTwoTone';
import CottageTwoToneIcon from '@mui/icons-material/CottageTwoTone';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import iconyigacoins from '../../assets/img/yigacoins/iconyigacoins.png';
import TvIcon from '@mui/icons-material/Tv';
import { useLocation } from 'react-router-dom'
export const SelectIcons = (props) => {
    return (
        <>
            {props.item === 0 &&
                <CottageTwoToneIcon style={{ color: '#00B2FF' }} />}
            {props.item === 1 &&
                <img src={iconyigacoins} alt='' width={25} />}
            {props.item === 2 &&
                <RocketLaunchTwoToneIcon style={{ color: '#00B2FF' }} />}
            {props.item === 3 &&
                <PlaceTwoToneIcon style={{ color: '#00B2FF' }} />}
            {props.item === 4 &&
                <SettingsTwoToneIcon style={{ color: '#00B2FF' }} />}
            {props.item === 5 &&
                <AssignmentTurnedInTwoToneIcon style={{ color: '#00B2FF' }} />}
            {props.item === 6 &&
                <ReceiptTwoToneIcon style={{ color: '#00B2FF' }} />}
            {props.item === 7 &&
                <NotificationsNoneTwoToneIcon style={{ color: '#00B2FF' }} />}
            {props.item === 8 &&
                <TvIcon style={{ color: '#e59866' }} />}
            {props.item === 9 &&
                <NotificationsNoneTwoToneIcon style={{ color: '#00B2FF' }} />}
        </>
    )
}