import {
    Typography, Container, Box, CardContent, Card, TextField, Select,
    MenuItem, Grid, FormControl, Button, Stack, DialogActions, InputLabel, Autocomplete
} from '@mui/material'
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listPlanes, listTipoProblema, listUbicacion } from '../../redux/actions/parametersActions';
import { createCambioPlan, createSoporTecnico } from '../../redux/actions/serviciosActions';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate } from "react-router-dom";
import InputValidation from '../../components/utils/InputValidation';
import Swal from 'sweetalert2'

export const CambioPlanPage = () => {
    const dispatch = useDispatch()
    let navigate = useNavigate()
    const [zapping, setZapping] = useState("");
    //
    const [ubicacion, setUbicacion] = useState('')

    const [tipo, setTipo] = useState('')
    const [plan, setPlan] = useState('')
    const [filter, setFilter] = useState([])
    const [descripcion, setDescripcion] = useState({ campo: '', valido: true })
    //
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    //
    const getPlanes = useSelector(state => state.getPlanes)
    const { list_planes } = getPlanes
    //
    const createSoporteTecnico = useSelector(state => state.createSoporteTecnico)
    const { loading } = createSoporteTecnico
    //
    const ubicaciones = useSelector(state => state.ubicacion)
    const { list_ubicacion } = ubicaciones
    useEffect(() => {
        dispatch(listTipoProblema())
        dispatch(listUbicacion())
        dispatch(listPlanes())
    }, [])
    const handlePlane = (e) => {
        const p = list_planes.filter((item) => item.plan === e);
        console.log(e, 'plan')
        const data = p[0]
        console.log('data', data)
        setPlan(data)
    }
    const submitHandler = (e) => {
        e.preventDefault()
        Swal.fire({
            text: "¿Esta seguro de enviar?",
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, estoy seguro'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(createCambioPlan({
                    plan_nuevo: plan.plan,
                    descripciontipootro: descripcion.campo,
                    zapping: zapping
                }, navigate))
                // var myHeaders = new Headers();
                // myHeaders.append("Content-Type", "application/json");
                // var raw = JSON.stringify({
                //     "id_servicio": userInfo.servicios.id,
                //     "idcliente": userInfo.id,
                //     "costo": plan.costo,
                //     "idperfil": plan.id,
                //     "descripcion": plan.plan,
                //     "pppuser": userInfo.servicios.pppuser,
                //     "ppppass": userInfo.servicios.ppppass,
                // });
                // var requestOptions = {
                //     method: 'POST',
                //     headers: myHeaders,
                //     body: raw,
                //     redirect: 'follow'
                // };
                // fetch("https://us-central1-yiga5-24enlinea.cloudfunctions.net/api/cambio_plan", requestOptions)
                //     .then(response => response.text())
                //     .then(result => {
                //         const dataJSON = JSON.parse(result)
                //         console.log(dataJSON, 'json')
                //     })
                //     .catch(error => console.log('error', error));
            }
        })
    }
    const getServicio = () => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        fetch("https://serverbd.intercommerce.com.ec/apimikrowispservicio/?idcliente=049234", requestOptions)
            .then(response => response.text())
            .then(result => {
                let data = JSON.parse(result)
                data = data[0]
                var formdata = new FormData();
                //
                formdata.append("idcliente", data.idcliente);
                formdata.append("idperfil", '46729');
                formdata.append("nodo", data.nodo);
                formdata.append("descripcion", data.descripcion);
                formdata.append("costo", 1);
                formdata.append("ipap", data.ipap);
                formdata.append("mac", data.mac);
                formdata.append("ip", data.ip);
                formdata.append("instalado", data.instalado);
                formdata.append("antena", data.antena);
                formdata.append("pppuser", data.pppuser);
                formdata.append("ppppass", data.ppppass);
                formdata.append("emisor", data.emisor);
                formdata.append("user_ubnt", data.user_ubnt);
                formdata.append("pass_ubnt", data.pass_ubnt);
                formdata.append("tiposervicio", data.tiposervicio);
                formdata.append("status_user", data.status_user);
                formdata.append("tipoipv4", data.tipoipv4);
                formdata.append("redipv4", data.redipv4);
                formdata.append("coordenadas", data.coordenadas);
                formdata.append("direccion", data.direccion_principal);
                formdata.append("snmp_comunidad", data.snmp_comunidad);
                formdata.append("firewall_state", data.firewall_state);
                formdata.append("smartolt", data.smartolt);
                formdata.append("limitado", data.limitado);
                formdata.append("ppp_routes", data.ppp_routes);
                formdata.append("ppp_localaddress", data.ppp_localaddress);
                formdata.append("idnap", data.idnap);
                formdata.append("portnap", data.portnap);
                formdata.append("onu_sn", data.onu_sn);
                formdata.append("onu_ssid_wifi", data.onu_ssid_wifi);
                formdata.append("onu_password_wifi", data.onu_password_wifi);
                formdata.append("personalizados", data.personalizados);
                formdata.append("smartolt_catv", data.smartolt_catv);
                formdata.append("ipv6", '1');
                var requestOptions = {
                    method: 'POST',
                    body: formdata,
                    redirect: 'follow'
                };
                fetch("https://serverbd.intercommerce.com.ec/apimikrowispservicio/", requestOptions)
                    .then(response => response.text())
                    .then(result => {
                        Swal.fire({ icon: 'success', text: 'Ya se envio tu solicitud', })
                    })
                    .catch(error => console.log('error', error));

            })
            .catch(error => console.log('error', error));
    }
    //
    const inputs = [
        {
            label: 'NOMBRE ',
            value: userInfo.nombre
        },
        {
            label: 'CEDULA',
            value: userInfo.cedula
        },
        {
            label: 'ESTADO',
            value: userInfo.estado
        },
        {
            label: 'LINEA DE CONTRATO',
            value: userInfo.NUM_CONTRATO
        },
        {
            label: 'DIRRECION',
            value: userInfo.direccion_principal
        },
        {
            label: 'CORREO',
            value: userInfo.correo
        },
    ]
    const orderPlanes = () => {
        list_planes.forEach(function (item, index) {
            const mi_costo = parseInt(userInfo.servicio.costo)
            const costo = parseInt(item.costo)
            if (costo > mi_costo) {
                //filter
                //const name_ = 'DEFENDER'
                // if(item.plan.toLowerCase().includes(name_.toLowerCase())){
                //     setPlanes(current => [...current, item]);
                //     console.log(planes, 'planes')
                // }
            }
        })

    }
    function convertInter(value) {
        // item id
        // const string = value;
        // const match = string.match(/\d+/g);
        // var value1 = match[0];
        // var value2 = match[1];
        // value1 = value1 / 1024
        // value2 = value2 / 1024
        // return `${value1.toFixed(2)} MB / ${value2.toFixed(2)} MB`
        if (value == 354) {
            return '35 Mbps'
        }
        if (value == 355) {
            return '100 Mbps'
        }
        if (value == 356) {
            return '150 Mbps'
        }
        if (value == 357) {
            return '200 Mbps'
        }
        if (value == 358) {
            return '300 Mbps'
        }
        if (value == 374) {
            return '70 Mbps'
        }
    }
    const handleTipo = (e) => {
        setTipo(e)
        if (e == 2) {
            const idsToFilter = ["398", "397", "399"];
            const filteredArray = list_planes.filter(item => idsToFilter.includes(item.id));
            setFilter(filteredArray)
        } else {
            const idsToFilter = ["420", "407", "408", "416", "410"];
            const filteredArray = list_planes.filter(item => idsToFilter.includes(item.id));
            setFilter(filteredArray)
        }
    }
    function convertToMbps(value) {
        if (!value.includes("K/")) return null; // Validar formato

        const [downloadStr, uploadStr] = value.split("/");

        const downloadKbps = parseInt(downloadStr.replace("K", ""), 10);
        const uploadKbps = parseInt(uploadStr.replace("K", ""), 10);

        const downloadMbps = downloadKbps / 1000; // Convertir a Mbps
        const uploadMbps = uploadKbps / 1000;

        return `${downloadMbps} Mbps / ${uploadMbps} Mbps`;
    }
    return (

        <form onSubmit={submitHandler}>
            <Box pb={2}>
                <Typography
                    variant='h5'
                    color='textPrimary'
                    component='h1'
                    style={{ textTransform: 'capitalize' }}
                ><strong>Cambio Plan</strong></Typography>
            </Box>
            <Card>
                <CardContent>
                    <Grid container spacing={2}>
                        {inputs.map((item, index) => (
                            <Grid key={index} item md={6}>
                                <TextField
                                    fullWidth
                                    label={item.label}
                                    disabled
                                    value={item.value}
                                />
                            </Grid>
                        ))}
                    </Grid>
                    <Box py={2}>
                        {userInfo.servicio && userInfo.servicio &&
                            <>
                                {userInfo.servicio.perfil &&
                                    <TextField
                                        fullWidth
                                        label='PLAN ACTUAL'

                                        value={userInfo.servicio.perfil}
                                    />}
                            </>}
                    </Box>
                    <Stack spacing={2}>
                        <Box>
                            <FormControl fullWidth>
                                <InputLabel id="ubicacion">TIPO DE CONTRATO</InputLabel>
                                <Select
                                    labelId="ubicacion"
                                    id="ubicacion"
                                    value={tipo}
                                    label="TIPO DE CONTRATO"
                                    onChange={(e) => handleTipo(e.target.value)}
                                >
                                    <MenuItem value={1}>HOGAR</MenuItem>
                                    <MenuItem value={2}>CORPORATIVO</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>

                        <Box>
                            <FormControl fullWidth>
                                <InputLabel id="ubicacion">SELECCIONAR PLAN</InputLabel>
                                <Select
                                    labelId="ubicacion"
                                    id="ubicacion"
                                    value={plan.plan}
                                    label="SELECCIONAR PLAN"
                                    onChange={(e) => handlePlane(e.target.value)}
                                >
                                    {
                                        userInfo && filter.map((item, index) => (
                                            <MenuItem key={index} value={item.plan}>
                                                {item.plan} {'\u00A0'}{'\u00A0'} <strong>${item.costo}</strong> {'\u00A0'}{'\u00A0'} {convertToMbps(item.velocidad)}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </Box>
                        <Box>
                            <FormControl fullWidth>
                                <InputLabel id="ubicacion">SELECCIONAR MOTIVO</InputLabel>
                                <Select
                                    labelId="ubicacion"
                                    id="ubicacion"
                                    value={zapping}
                                    label="SELECCIONAR MOTIVO"
                                    onChange={(e) => setZapping(e.target.value)}
                                >
                                    <MenuItem value="FACTOR ECONÓMICO">
                                        FACTOR ECONÓMICO
                                    </MenuItem>
                                    <MenuItem  value="FALTA DE USO">
                                        FALTA DE USO
                                    </MenuItem>
                                    <MenuItem value="SERVICIO NO CUMPLE CON EXPECTATIVAS">
                                        SERVICIO NO CUMPLE CON EXPECTATIVAS
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <InputValidation
                            estado={descripcion}
                            cambiarEstado={setDescripcion}
                            multiline={true}
                            label="MOTIVO POR EL CUAL DESEA CAMBIAR SU PLAN"
                            name="motivo"
                            rows={4}
                            maxRows={4}
                            disabled={loading}
                        />
                    </Stack>
                </CardContent>
            </Card>
            {/* <button onClick={() => getServicio()}>Ejectura funcion</button> */}
            <Box py={2}>
                <DialogActions>
                    <LoadingButton
                        size='large'
                        loading={loading}
                        disabled={loading || !plan || !zapping}
                        type="submit"
                        variant="contained"
                    >
                        Enviar informacion
                    </LoadingButton>
                </DialogActions>
            </Box>
        </form>

    )
}
